import store from '@/store';
import { formatValue, formatBigNumber, $_helper_isNumberType, $_helper_isNumberType_bySetting, formatNumberBySetting, removeSpecialChars, math } from '@/concerns/newRegisterData/wijmo.helper';
import {validateDate} from '@/utils/validate';
import {formatNumberRealNum} from '@/utils/convertNumber';
import { listAllDbIdea, dbIdeaBySourceName } from '@/api/ideaDbSource';
import { scopeCategorySourcesData } from '@/constants/export-report';
import { prepareMasterOrCustomizeDB, makeNewId } from '@/concerns/utils/master-or-customize-db'
import { dbServiceLinkBySourceName } from '@/api/serviceLink';
import { ROUTES } from '@/router/constants';
import router from '@/router';
import { COMMERCIAL_DATA_TYPE1, CAR_LOAN_TYPE, DATA_TYPE, GICS_PULLDOWN, SCOPE_TYPE, CURRENCY_TYPE } from '@/constants/registerData';
import i18n from '@/lang/i18n';
import { handleCalcDefaultDataRate, calcFuelVal, TKM_UNIT_SOURCE, listBasicUnitTkm, isTkmBasicUnitName } from '@/constants/tkm-method'

const errorCsv = 'データに不具合があるため、取り込みできませんでした。CSVファイルの中身を確認してください。'
import { getWidthByTextContent } from '@/utils/calcTextWidth';
import { DB_TYPE } from '@/constants/dbType';
import { getDbRelationKeysByType } from '@/utils/sourceExternal';
export const convertCsvToJson = (csvData, flexgrid) => {
  // convert csv data to json
  const array = csvData.split("\n");
  const csvToJsonResult = [];

  const headers = array[0].split(",");
  let headersBinding = [];
  flexgrid?.columns.forEach(column => {
    const headerIndex = headers.findIndex(header => column.header === header.trim());
    if (headerIndex >= 0) {
      headersBinding.push(column.binding);
    }
  })
  for (let i = 1; i < array.length - 1; i++) {
  /* Empty object to store result in key value pair */
  const jsonObject = {}
  /* Store the current array element */
  const currentArrayString = array[i]

  let jsonProperties = currentArrayString.split(",")
  for (let j in headersBinding) {
    jsonObject[headersBinding[j]] = jsonProperties[j]
  }
  /* Push the genearted JSON object to resultant array */
  csvToJsonResult.push(jsonObject)
  }
  return csvToJsonResult;
  /* Convert the final array to JSON */
  return JSON.stringify(csvToJsonResult);
}

const energyTypeS1m2 = ['ガソリン','軽油', 'その他']

const targetModel = ['軽貨物車', '小型貨物車', '普通貨物車', '船舶', '鉄道', '航空']

const types = [
  {key: 1, value: '産業廃棄物'},
  {key: 2, value: '一般廃棄物'},
  {key: 3, value: '不明'},
]


const autoFieldDbS1M1 = (rowData, dbSource) => {
  rowData.unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.source || null
  rowData.energy_type = dbSource?.id || null
}

const autoFieldDbS1M2 = (rowData, dbSource) => {
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
  // if(!energyTypeS1m2.includes(rowData?.fuel)) {
  //   rowData.fuel = null
  // }

  if(!targetModel.includes(rowData?.target_model)) {
    rowData.target_model = null
  }
}

const autoFieldDbS1M3 = (rowData, dbSource) => {
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.source || null
  rowData.fuel = dbSource?.id || null
  if(!dbSource?.source) {
    rowData.fuel = null
  }
}

const autoFieldDbS2 = (rowData, dbSource) => {
  rowData.unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.source || null
  rowData.energy_type = dbSource?.id || null
}

const autoFieldDbS3C1 = (rowData, dbSource) => {
  rowData.supplier_name = dbSource?.item_name || null
  rowData.qd_unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
  if (dbSource?.isExternalSource) {
    rowData.supplier_name = dbSource?.source || null
  }
}

const autoFieldDbS3C2 = (rowData, dbSource) => {
  rowData.cpa_unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
}

const autoFieldDbS3C3 = (rowData, dbSource) => {
  rowData.energy_type = dbSource?.item_name || null
  rowData.energy_unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
  if (dbSource?.isExternalSource) {
    rowData.energy_type = dbSource?.source || null
  }
}

const autoFieldDbS3C4M1Sub4 = (rowData, dbSource) => {
  rowData.unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.source || null
  rowData.energy_type = dbSource?.id || null
}
const autoFieldDbS3C4M1Sub5 = (rowData, dbSource) => {
  rowData.wsu_source = dbSource?.id || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  // if(!energyTypeS1m2.includes(rowData?.fuel)) {
  //   rowData.fuel = null
  // }

  if(!targetModel.includes(rowData?.target_model)) {
    rowData.target_model = null
  }
}
const autoFieldDbS3C4M1Sub6 = (rowData, dbSource) => {
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.source || null
  rowData.fuel = dbSource?.id || null
  if(!dbSource?.source) {
    rowData.fuel = null
  }
}
const autoFieldDbS3C4M2Sub7 = (rowData, dbSource) => {
  rowData.unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.source || null
  rowData.energy_type = dbSource?.id || null
}

const autoFieldDbS3C4M2Sub8 = (rowData, dbSource) => {
  rowData.unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.source || null
  rowData.energy_type = dbSource?.id || null
}
const autoFieldDbS3C4M3Sub2 = (rowData, dbSource) => {}

const processing_methods = [
  '焼却',
  '埋立',
  'リサイクル',
  '不明'
]

const autoFieldDbS3C5 = (rowData, dbSource) => {
  rowData.unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
  let type = types.find(item => item.value === rowData.wsu_type);
  if(!processing_methods.includes(rowData.processing_method)){
    rowData.processing_method = null
  }
  if(type) {
    rowData.wsu_type = type.key
  } else {
    rowData.wsu_type = null
  }
}

const autoFieldDbS3C6M1 = (rowData, dbSource) => {
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
}
const autoFieldDbS3C6M2 = (rowData, dbSource) => {
  rowData.unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.source || null
  rowData.energy_type = dbSource?.id || null
}
const autoFieldDbS3C6M3 = (rowData, dbSource) => {
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.source || null
  rowData.fuel = dbSource?.id || null
  if(!dbSource?.source) {
    rowData.fuel = null
  }
}
const autoFieldDbS3C6M4 = (rowData, dbSource) => {
  rowData.travel_unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
}
const autoFieldDbS3C6M5 = (rowData, dbSource) => {
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
}
const autoFieldDbS3C6M6 = (rowData, dbSource) => {
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
}
const autoFieldDbS3C6M7 = (rowData, dbSource) => {
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
}

const autoFieldDbS3C7M1 = (rowData, dbSource) => {
  rowData.te_unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
}
const autoFieldDbS3C7M2 = (rowData, dbSource) => {
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
}
const autoFieldDbS3C7M3 = (rowData, dbSource) => {
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
}
const autoFieldDbS3C7M4 = (rowData, dbSource) => {
  rowData.unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.source || null
  rowData.energy_type = dbSource?.id || null
}
const autoFieldDbS3C7M5 = (rowData, dbSource) => {
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.source || null
  rowData.fuel = dbSource?.id || null
}

const autoFieldDbS3C8 = (rowData, dbSource) => {
  rowData.lease_asset_name = dbSource?.item_name || null
  rowData.iss_unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
  if (dbSource?.isExternalSource) {
    rowData.lease_asset_name = dbSource?.source || null
  }
}

const autoFieldDbS3C9M1 = (rowData, dbSource) => {
  rowData.unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.source || null
  rowData.energy_type = dbSource?.id || null
}
const autoFieldDbS3C9M2 = (rowData, dbSource) => {
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
  // if(!energyTypeS1m2.includes(rowData?.fuel)) {
  //   rowData.fuel = null
  // }

  if(!targetModel.includes(rowData?.target_model)) {
    rowData.target_model = null
  }
}
const autoFieldDbS3C9M3 = (rowData, dbSource) => {
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.source || null
  rowData.fuel = dbSource?.id || null
  if(!dbSource?.source) {
    rowData.fuel = null
  }
}
const autoFieldDbS3C9M4 = (rowData, dbSource) => {
  rowData.unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.source || null
  rowData.energy_type = dbSource?.id || null
}
const autoFieldDbS3C9M5 = (rowData, dbSource) => {
  rowData.unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.source || null
  rowData.energy_type = dbSource?.id || null
}

const autoFieldDbS3C10 = (rowData, dbSource) => {
  rowData.intermediate_product = dbSource?.item_name || null
  rowData.sales_volume_unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
  if (dbSource?.isExternalSource) {
    rowData.intermediate_product = dbSource?.source || null
  }
}

const autoFieldDbS3C11M1 = (rowData, dbSource) => {
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
}
const autoFieldDbS3C11M2 = (rowData, dbSource) => {
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
}
const autoFieldDbS3C11M3 = (rowData, dbSource) => {
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
}
const autoFieldDbS3C11M4 = (rowData, dbSource) => {
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
}

const autoFieldDbS3C12 = (rowData, dbSource) => {
  rowData.unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
  if(!processing_methods.includes(rowData.processing_method)){
    rowData.processing_method = null
  }

  let type = types.find(item => item.value === rowData.wsu_type);
  if(type) {
    rowData.wsu_type = type.key
  } else {
    rowData.wsu_type = null
  }
}

const autoFieldDbS3C13 = (rowData, dbSource) => {
  rowData.lease_asset_name = dbSource?.item_name || null
  rowData.scale_index_unit = dbSource?.unit || null
  rowData.wsu_value = dbSource?.value_source || null
  rowData.wsu_unit = dbSource?.unit_source || null
  rowData.wsu_source = dbSource?.id || null
  if (dbSource?.isExternalSource) {
    rowData.lease_asset_name = dbSource?.source || null
  }
}

const autoFieldDbS3C15 = (rowData, dbSource, isTopDownPattern = false) => {
  rowData.ides_industry = dbSource?.id || null;
  // if(isTopDownPattern) {
  //   rowData.carbon_value = dbSource?.value_source
  // }
  if(rowData?.dq_sharpe_ratio && ![1,2,3,4,5].includes(parseInt(rowData?.dq_sharpe_ratio))) {
    rowData.dq_sharpe_ratio = null
  }
  if(rowData?.dq_quality_score && ![1,2,3,4,5].includes(parseInt(rowData?.dq_quality_score))) {
    rowData.dq_quality_score = null
  }
}
const calculationEmissionS3C15M1 = (rowData) => {
  rowData.ir_owned = calcS3C15M134(rowData.ir_company_hold, rowData.ir_release_quantity)
  if(!$_helper_isNumberType(rowData.ie_year) || formatValue(rowData.ie_year)?.length !== 4) {
    rowData.ie_year = null;
  }
}

const calculationEmissionS3C15M3 = (rowData) => {
  rowData.pj_investment_ratio = calcS3C15M134(rowData.ir_internal_investment, rowData.ir_total_investment)
  if(!$_helper_isNumberType(rowData.ie_year) || formatValue(rowData.ie_year)?.length !== 4) {
    rowData.ie_year = null;
  }
}

const calculationEmissionS3C15M2 = (rowData) => {
  rowData.ir_investment_on_total = calcS3C15M134(rowData.ir_total_investment, rowData.ir_internal_investment)
  if(!$_helper_isNumberType(rowData.ie_year) || formatValue(rowData.ie_year)?.length !== 4) {
    rowData.ie_year = null;
  }
}

const calculationEmissionS3C15M4 = (rowData) => {
  rowData.ir_ratio = calcS3C15M134(rowData.ir_calculation_numerator, rowData.ir_calculated_denominator)
  if(!$_helper_isNumberType(rowData.ie_year) || formatValue(rowData.ie_year)?.length !== 4) {
    rowData.ie_year = null;
  }
}

const calculationEmissionS3C15Pcaf = (dataProps) => {
  const {rowData, dbSource, method, qualityScore, gicObject, dataGicsByYear} = dataProps
  if(![1,2,3,4,5].includes(Number(rowData.dq_quality_score))) {
    rowData.dq_quality_score = null
  }

  if(!CURRENCY_TYPE.includes(rowData?.currency)) {
    rowData.currency = CURRENCY_TYPE[0]
  }
  const gicItem = dataGicsByYear.find(item => item.key === rowData?.classify_gics)
  if(rowData?.classify_gics && !gicItem) {
    rowData.classify_gics = null
  }
  if(method !== 7 && !rowData?.emissions_factor) { //set emissions_factor if rowdata dont set emissions_factor
    rowData.emissions_factor = gicItem?.autoField ? formatNumberRealNum(gicItem?.autoField?.substring(0, 25), { isAlowMore25digit: false}) : formatNumberRealNum(dbSource?.value_source, { isAlowMore25digit: false})
  }

  if(rowData?.scope && !SCOPE_TYPE.includes(rowData?.scope)) {
    rowData.scope = null
  }

  if (!rowData.dq_quality_score) {
    rowData.dq_quality_score = qualityScore
  }

  if([1,2,3].includes(method)) {
    if(!rowData?.emissions_factor) {
      rowData.emissions_factor = formatNumberRealNum(dbSource?.value_source, { isAlowMore25digit: false}) || null
    }
  }
}

const getScoreByTypePattern1 = typeData => {
  if(!typeData) return [null, null]

  let valueDataType2 = null
  let dq_quality_score = '2'
  switch (typeData) {
    case DATA_TYPE[0]['key']:
      dq_quality_score = '2'
      break;
    case DATA_TYPE[1]['key']:
      dq_quality_score = '2'
      break;
    case DATA_TYPE[2]['key']:
      dq_quality_score = '3'
      break;
    default:
      dq_quality_score = '5'
      break;
  }

  return [dq_quality_score, valueDataType2]
}

const getScoreByTypePattern4 = typeData => {
  if(!typeData) return [null, null]

  let valueDataType2 = null
  let dq_quality_score = null
  switch (typeData) {
    case COMMERCIAL_DATA_TYPE1[0]['key']:
      valueDataType2 = null
      dq_quality_score = '2'
      break;
    case COMMERCIAL_DATA_TYPE1[1]['key']:
      valueDataType2 = i18n.t("value_commercial_data_type_1.floor_space")
      dq_quality_score = '4'
      break;
    case COMMERCIAL_DATA_TYPE1[2]['key']:
      valueDataType2 = i18n.t("value_commercial_data_type_1.number_of_buildings")
      dq_quality_score = '5'
      break;
    default:
      valueDataType2 = null
      dq_quality_score = null
      break;
  }

  return [dq_quality_score, valueDataType2]
}

const getScoreByTypePattern6 = typeData => {
  if(!typeData) return [null, null]

  let valueDataType2 = null
  let dq_quality_score = '1'
  switch (typeData) {
    case CAR_LOAN_TYPE[0]['key']:
      valueDataType2 = null
      dq_quality_score = '1'
      break;
    case CAR_LOAN_TYPE[1]['key']:
      valueDataType2 = i18n.t("value_car_loan_type.fuel_consumption")
      dq_quality_score = '1'
      break;
    case CAR_LOAN_TYPE[2]['key']:
      valueDataType2 = i18n.t("value_car_loan_type.fuel_consumption")
      dq_quality_score = '5'
      break;
    default:
      valueDataType2 = null
      dq_quality_score = null
      break;
  }

  return [dq_quality_score, valueDataType2]
}

const autoFieldS3C15PcafTopDownMethod = rowData => {
  const valFirstValue = math.bignumber(formatValue(rowData?.carbon_value));
  const valSecondValue = math.bignumber(formatValue(rowData?.revenue));
  const ghgEmissions = formatBigNumber(math.evaluate(`${valFirstValue.toString()} * ${valSecondValue.toString()} `))
  rowData.ie_ghg_emissions = ghgEmissions
}

const calcS3C15M134 = (firstValue, secondValue) => {
  if (secondValue === '0') {
    return '0.00'
  }
  if ($_helper_isNumberType_bySetting(firstValue) && $_helper_isNumberType_bySetting(secondValue)) {
    const valFirstValue = math.bignumber(formatNumberBySetting(firstValue));
    const valSecondValue = math.bignumber(formatNumberBySetting(secondValue));
    return formatNumberRealNum(formatBigNumber(math.evaluate(`${valFirstValue.toString()} / ${valSecondValue.toString()}`), 50))
  }
  return null;
}

const autoFieldDbS4 = (rowData) => {
  const nameSelect = rowData.certification_standard_name_id
  const typeSelect = rowData.certification_standard_type_id
  const certificationCustome = store.getters['registerData/getCertificationTypeCustome']
  const certificationTypes = store.getters['registerData/getCertificationTypeObj']
  const certificationNames = store.getters['registerData/getCertificationNameObj']
  const cetifiSelected = certificationCustome[nameSelect + typeSelect]
  const defaultEmissionFactorTco2 = '1'
  const defaultEmissionFactorUnit = 't-CO2'
  const defaultEmissionFactorVal = '0.000396'

  if(!certificationTypes[typeSelect]) {
    rowData.certification_standard_type_id = null
  }
  if(!certificationNames[nameSelect]) {
    rowData.certification_standard_name_id = null
  }

  if(!['t-CO2', 'kWh', 'MJ']?.includes(rowData.purchase_level_unit)) {
    rowData.purchase_level_unit = null
    rowData.emission_factor_unit = null
  }

  if(cetifiSelected && !cetifiSelected.unit.includes(rowData.purchase_level_unit)) {
    rowData.dir_unit = 't-CO2';
    rowData.purchase_level_unit = cetifiSelected.unit[0]
    rowData.emissions_factor = cetifiSelected.unit[0] === defaultEmissionFactorUnit ? defaultEmissionFactorTco2 : rowData.emissions_factor
    rowData.emission_factor_unit = cetifiSelected.unit[0] === defaultEmissionFactorUnit ?  defaultEmissionFactorUnit : `${rowData.purchase_level_unit}/${defaultEmissionFactorUnit}`
  } else if(cetifiSelected && cetifiSelected.unit.includes(rowData.purchase_level_unit)) {
    rowData.dir_unit = 't-CO2';
    rowData.emission_factor_unit = rowData.purchase_level_unit === defaultEmissionFactorUnit ? defaultEmissionFactorUnit : `${rowData.purchase_level_unit}/${defaultEmissionFactorUnit}`
    rowData.emissions_factor = rowData.purchase_level_unit === defaultEmissionFactorUnit ? defaultEmissionFactorTco2 : rowData.emissions_factor
  } else {
    rowData.emission_factor_unit = null
    rowData.emissions_factor = null
    rowData.purchase_level_unit = null
    rowData.dir_unit = null;
  }

  // if(!rowData.emissions_factor) {
  //   rowData.emissions_factor = defaultEmissionFactorVal
  // }
}
const addExternalSource = (scope, category,method, methodLayer2, data) => {
  // addExternalSource
  // TODO: remove get data from state
  
  let ideaSource = JSON.parse(JSON.stringify(store.state.registerData));
  const values = data;
  const [ nameSource, valueSource, unitSource ] = values.content;
  const sourcesSelection = {};
  for (const c of scopeCategorySourcesData) {
    if (
      (c.scope === undefined || c.scope === scope) &&
      (c.category === undefined || c.category === category) &&
      (c.method === undefined || c.method === method) &&
      (c.methodLayer2 === undefined || c.methodLayer2 === methodLayer2)
    ) {
      sourcesSelection['data'] = c.data;
      sourcesSelection['dbKey'] = c.dbKey;
    }
  }
  ideaSource.dbCustomizes.push({
    ...values,
    id: values.origin_id,
    [sourcesSelection.dbKey]: nameSource,
    unit_source: unitSource,
    value_source: valueSource
  });
  store.dispatch('registerData/updateListDbCustomize', ideaSource.dbCustomizes, {root: true});
}
export const handleLostFocus = (data, callBack) => {
  const isTemplateStatus = router.currentRoute.params.scope === ROUTES.LIST_EMISSION_TEMPLATES
  const { 
    ctx,
    sender,
    dbStore,
    grid,
    isRemoveStatusColumn,
    isRemoveEmissionFactorOrValNumber,
    basicUnitData,
    category
  } = data
  const item = sender.text;

  let searchIdExternal = null;
  const index = dbStore.dbCustomizes?.findIndex(d => d.source === item);
  if (item && !dbStore.getDbCustomizeOrDbMasterByNameSource?.includes(item) && index === -1) {
    const dbItem = handleFindDbItem(item, basicUnitData, category)
    
    if (dbItem) {
      let dbItemDetail = {}
      if([DB_TYPE.MASTER, DB_TYPE.CUSTOMIZE]?.includes(dbItem.type)) {
        dbItemDetail = dbItem
      } else {
        dbItemDetail = {...dbItem, item_name: dbItem.source, type: dbItem?.type, numberValue: dbItem?.numberValue }
      }

      const newId = makeNewId(dbItemDetail.id, dbItemDetail.type);
      searchIdExternal = newId;
      addedDbExternalToMasterDB(dbStore, {...dbItemDetail, item_name: dbItemDetail.source });

      const header = callBack({ ...data, isPastingDbIdea: false });
      header.forEach(h => {
        if(h?.columns?.length) {
          h?.columns.forEach(subColumn => {
            const { header, minWidth } = subColumn;
            const contentWidth = getWidthByTextContent(header);
            subColumn.minWidth = Math.max(minWidth || 1, contentWidth);
          })
        } else {
          const { header, minWidth } = h;
          const contentWidth = getWidthByTextContent(header);
          h.minWidth = Math.max(minWidth || 1, contentWidth);
        }
      });
      sender.itemsSource.push({ ...dbItemDetail, item_name: dbItemDetail.source, type: dbItemDetail.type });
      const statusColumn = header[header.length - 1]
      statusColumn.minWidth = getWidthByTextContent(statusColumn?.header) + 10

      if (isTemplateStatus) {
        removeHeaderItem(header, 'attach_file')
        removeHeaderItem(header, 'status')
      }

      if(isRemoveStatusColumn) {
        removeHeaderItem(header, 'status')
      }
      if(isRemoveEmissionFactorOrValNumber) {
        removeHeaderItem(header, 'value_number_2')
      }
      grid.columnGroups = header;
    }
  }
  return searchIdExternal
}
export const handlePastingCell = (propsHeader) => {
  const { dbStore, grid, rowIndex, item, basicUnitData, category } = propsHeader
  let searchIdExternal = null;
  const dbItem = handleFindDbItem(item, basicUnitData, category)
  if (dbItem) {
    const newId = makeNewId(dbItem.id, dbItem.type);
    searchIdExternal = newId;
    let dbItemDetail = {}
    if([DB_TYPE.MASTER, DB_TYPE.CUSTOMIZE].includes(dbItem.type)) {
      dbItemDetail = dbItem
    } else {
      dbItemDetail = {...dbItem, item_name: dbItem.source, type: dbItem?.type, numberValue: dbItem?.numberValue }
    }

    addedDbExternalToMasterDB(dbStore, dbItemDetail);
    if(grid.collectionView.sourceCollection[rowIndex] && dbItem?.type !== DB_TYPE.MASTER) {
      const dbRelationKey = getDbRelationKeysByType(dbItem.type);
      grid.collectionView.sourceCollection[rowIndex].idDbExternal = newId
      grid.collectionView.sourceCollection[rowIndex].db_relation = {
        [dbRelationKey]: dbItemDetail?.id,
      }
    }
    return dbItem?.type === DB_TYPE.MASTER ? null : searchIdExternal
  }

  return searchIdExternal
}


const handleFindDbItem = (item, basicUnitData, category) => {
  // if(category === 15) {
  //   return dbIdeaBySourceName[item] || dbServiceLinkBySourceName[item];
  // }
  return basicUnitData.source[item] || dbServiceLinkBySourceName[item];
}

const addedDbExternalToMasterDB = (dbStore, sourceTemplate) => {
  const typeSource = sourceTemplate.type;
  dbStore.dbExternalAdded[typeSource].push(sourceTemplate);
}

const removeHeaderItem = (header, bindingName) => {
  const indexRemoved = header.findIndex(h => h.binding === bindingName);
  if (indexRemoved !== -1) {
    header.splice(indexRemoved, 1);
  }
  
  if(indexRemoved === -1) {
    header.forEach(headerItem => {
      if(headerItem.columns?.length > 0) {
        const subIndexRemoved = headerItem.columns.findIndex(sub => sub.binding === bindingName);
        if (subIndexRemoved !== -1) {
          headerItem.columns.splice(subIndexRemoved, 1);
        }
      }
    })
  }
}
const bindDingValueSource = (dataProps) => {
  const {rowData, scope, category, dbSource, method, methodLayer2, isSettingPcaf, calcEmissions, qualityScore, gicObject, dataGicsByYear} = dataProps
  // bindding auto field for scope 1
  if(scope === 1) {
    if(method === 2) {
      autoFieldDbS1M1(rowData, dbSource)
    } else if(method === 3) {
      autoFieldDbS1M3(rowData, dbSource)
    } else if(method === 1) {
      autoFieldDbS1M1(rowData, dbSource)
    } else {
      autoFieldDbS1M2(rowData, dbSource)
    }
  } else if(scope === 2) { // bindding auto field for scope 2
    autoFieldDbS2(rowData, dbSource)
  } else if(scope === 3) { // bindding auto field for scope 3
    switch (category) {
      case 1:
        autoFieldDbS3C1(rowData, dbSource)
        break;
      case 2:
        autoFieldDbS3C2(rowData, dbSource)
        break;
      case 3:
        autoFieldDbS3C3(rowData, dbSource)
        break;
      case 4:
        if(methodLayer2 === 4) {
          autoFieldDbS3C4M1Sub4(rowData, dbSource)
        } else if(methodLayer2 === 6) {
          autoFieldDbS3C4M1Sub5(rowData, dbSource)
        } else if(methodLayer2 === 5) {
          autoFieldDbS3C4M1Sub6(rowData, dbSource)
        } else if(methodLayer2 === 7) {
          autoFieldDbS3C4M2Sub7(rowData, dbSource)
        } else if(methodLayer2 === 8) {
          autoFieldDbS3C4M2Sub8(rowData, dbSource)
        }
        break
      case 5:
        autoFieldDbS3C5(rowData, dbSource)
        break;
      case 6:
        if(method === 3) {
          autoFieldDbS3C6M1(rowData, dbSource)
        } else if(method === 1) {
          autoFieldDbS3C6M2(rowData, dbSource)
        } else if(method === 2) {
          autoFieldDbS3C6M3(rowData, dbSource)
        } else if(method === 4) {
          autoFieldDbS3C6M4(rowData, dbSource)
        } else if(method === 5) {
          autoFieldDbS3C6M5(rowData, dbSource)
        } else if(method === 6) {
          autoFieldDbS3C6M6(rowData, dbSource)
        } else if(method === 7) {
          autoFieldDbS3C6M7(rowData, dbSource)
        }
        break;
      case 7:
        if(method === 3) {
          autoFieldDbS3C7M3(rowData, dbSource)
        } else if(method === 1) {
          autoFieldDbS3C7M4(rowData, dbSource)
        } else if(method === 2) {
          autoFieldDbS3C7M5(rowData, dbSource)
        } else if(method === 4) {
          autoFieldDbS3C7M1(rowData, dbSource)
        } else if(method === 5) {
          autoFieldDbS3C7M2(rowData, dbSource)
        }
        break;
      case 8:
        autoFieldDbS3C8(rowData, dbSource)
        break;
      case 9:
        if(methodLayer2 === 1) {
          autoFieldDbS3C9M1(rowData, dbSource)
        } else if(methodLayer2 === 3) {
          autoFieldDbS3C9M2(rowData, dbSource)
        } else if(methodLayer2 === 2) {
          autoFieldDbS3C9M3(rowData, dbSource)
        } else if(methodLayer2 === 4) {
          autoFieldDbS3C9M4(rowData, dbSource)
        } else if(methodLayer2 === 5) {
          autoFieldDbS3C9M5(rowData, dbSource)
        }
        break;
      case 10:
        autoFieldDbS3C10(rowData, dbSource)
        break;
      case 11:
        if(method === 2) {
          autoFieldDbS3C11M1(rowData, dbSource)
        } else if(methodLayer2 === 1) {
          autoFieldDbS3C11M2(rowData, dbSource)
        } else if(methodLayer2 === 2) {
          autoFieldDbS3C11M3(rowData, dbSource)
        } else if(methodLayer2 === 3) {
          autoFieldDbS3C11M4(rowData, dbSource)
        }
        break;
      case 12:
        autoFieldDbS3C12(rowData, dbSource)
        break;
      case 13:
        autoFieldDbS3C13(rowData, dbSource)
        break;
      case 15:
        autoFieldDbS3C15(rowData, dbSource)
        if(isSettingPcaf) {
          calculationEmissionS3C15Pcaf({rowData, dbSource, method, qualityScore, gicObject, dataGicsByYear})
        } else {
          if(method === 1) {
            calculationEmissionS3C15M1(rowData)
          } else if(method === 3) {
            calculationEmissionS3C15M3(rowData)
          } else if(method === 4) {
            calculationEmissionS3C15M4(rowData)
          } else {
            calculationEmissionS3C15M2(rowData)
          }
        }
        break;
      default:
        break;
    }
  } else {
    autoFieldDbS4(rowData)
    rowData.dir_value = calcEmissions(rowData)
    if(validateDate(rowData.dir_date)) {
      rowData.dir_date = null
    }
  }
}

const getSubstringRange = (columnHeader) => {
  return ['燃費（km/l）', '平均積載率（%）', 'PJ投資割合（％）'].some(item => columnHeader.includes(item)) ? 10 : 25
}

const getIdeaDbItemName = () => {
  let result = {}

  prepareMasterOrCustomizeDB(listAllDbIdea).forEach((item) => {
    if (!result[item.source]) {
      result[item.source] = {...item, 'isExternalSource' : true}
    }
  });
  return result
}

// return object with key is item name or source, define for each pattern will difference
const getObjSource = (scope, category, method, methodLayer2, jsonObject, dbStore, sourceKeyByWsuSource, sourceKeyByItemName, isSettingPcaf, basicUnitData) => {
  let objSource = {}
  // if(scope === 1) {
  //   if(method === 2) {
  //     objSource = sourceKeyByItemName[jsonObject.energy_type]
  //   } else if(method === 3) {
  //     objSource = sourceKeyByItemName[jsonObject.fuel]
  //   } else if(method === 1) {
  //     objSource = sourceKeyByItemName[jsonObject.energy_type]
  //   } else {
  //     objSource = sourceKeyByWsuSource[jsonObject.wsu_source]
  //   }
  // } else if(scope === 2) {
  //   objSource = sourceKeyByItemName[jsonObject.energy_type]
  // } else if(scope === 3) {
  //   switch (category) {
  //     case 1:
  //     case 2:
  //     case 3:
  //     case 5:
  //     case 8:
  //     case 10:
  //     case 11:
  //     case 12:
  //     case 13:
  //       objSource = sourceKeyByWsuSource[jsonObject.wsu_source]
  //       break;
  //     case 15:
  //       if(isSettingPcaf) {
  //         objSource = sourceKeyByWsuSource[jsonObject.ides_industry]
  //       } else {
  //         objSource = sourceKeyByItemName[jsonObject.ides_industry]
  //       }
  //       break;
  //     case 4:
  //       if([4, 7, 8].includes(methodLayer2)) {
  //         objSource = sourceKeyByItemName[jsonObject.energy_type]
  //       } else if(methodLayer2 === 6) {
  //         objSource = sourceKeyByWsuSource[jsonObject.wsu_source]
  //       } else if(methodLayer2 === 5) {
  //         objSource = sourceKeyByItemName[jsonObject.fuel]
  //       }
  //       break
  //     case 6:
  //       if([3, 4, 5, 6, 7].includes(method)) {
  //         objSource = sourceKeyByWsuSource[jsonObject.wsu_source]
  //       } else if(method === 1) {
  //         objSource = sourceKeyByItemName[jsonObject.energy_type]
  //       } else {
  //         objSource = sourceKeyByItemName[jsonObject.fuel]
  //       }
  //       break;
  //     case 7:
  //       if(method === 1) {
  //         objSource = sourceKeyByItemName[jsonObject.energy_type]
  //       } else if([3,4,5].includes(method)) {
  //         objSource = sourceKeyByWsuSource[jsonObject.wsu_source]
  //       } else  {
  //         objSource = sourceKeyByItemName[jsonObject.fuel]
  //       }
  //       break;
  //     case 9:
  //       if([1, 4, 5].includes(methodLayer2)) {
  //         objSource = sourceKeyByItemName[jsonObject.energy_type]
  //       } else if(methodLayer2 === 3) {
  //         objSource = sourceKeyByWsuSource[jsonObject.wsu_source]
  //       } else if(methodLayer2 === 2) {
  //         objSource = sourceKeyByItemName[jsonObject.fuel]
  //       }
  //       break;
  //     default:
  //       objSource = sourceKeyByWsuSource[jsonObject.wsu_source]
  //       break;
  //   }
  // }
  objSource = basicUnitData?.source?.[jsonObject?.wsu_source] || basicUnitData?.source?.[jsonObject?.energy_type] || basicUnitData?.source?.[jsonObject?.fuel] || {}
  if(category === 15) {
    objSource = sourceKeyByItemName[jsonObject?.ides_industry]
  }
  return objSource
}

// compare headerCsv & headerInPattern before check data csv
const compareHeader = (headerInPattern, headerCsv) => {
  if (headerInPattern.length !== headerCsv.length) {
    return false;
  }
  // slice length headerCsv to length headerInPattern
  headerCsv = headerCsv.slice(0, headerInPattern.length)
  for (let i = 0; i < headerInPattern.length; i++) {
    if (headerInPattern[i] !== headerCsv[i]?.trim()) {
      return false;
    }
  }

  return true;
}
const cleanArray = (arr) => {
  return arr.map((element) => {
    const trimmedElement = element.trim();
    if (trimmedElement.match(/^"\s*\d+,\d+\.\d+\s*"$/)) {
      const numericValue = parseFloat(trimmedElement.replace(/[",\s]/g, ''));
      return numericValue.toString();
    }
    return trimmedElement;
  }).filter((element) => element !== '');
}
const convertToArray = (text) => {
  const delimiter = ',';
  const specialDelimiter = '~';
  const regex = /""/g;
  const textWithDelimiters = text.replace(regex, specialDelimiter);
  return textWithDelimiters.split(delimiter).map((field) => field.replace(new RegExp(specialDelimiter, 'g'), '"').trim());
}

const removeLastAsterisk = text => {
  let lastAsteriskIndex = text.lastIndexOf('*');
  if(lastAsteriskIndex !== -1) {
    return text.slice(0, lastAsteriskIndex) + text.slice(lastAsteriskIndex + 1); //slice last asterisk
  }

  return text
}

export const convertCsvToJsonNew = (data, bindingDataSource, handleAddDbMasterToList) => {
  const { 
    csvData,
    header,
    scope,
    category,
    numberItem,
    calcEmissions,
    listBranchObj,
    method,
    methodLayer2,
    isSettingPcaf,
    dbStore,
    isLimitedAttributionFactor,
    qualityScore,
    dataGicsByYear,
    basicUnitData,
    isTkmMethod
  } = data
  
  if(!csvData) return

  const array = csvData.split("\n");
  const csvToJsonResult = [];
  let headers = convertStringToArray(array[0]);
  headers = headers.map(item => removeLastAsterisk(item))  
  const iss3c4m3 = category === 4 && method === 3
  headers = updateHeaderCsvForS3c4m3(headers, iss3c4m3)

  let error = '';
  let headersBinding = [];
  let headerMainSubCategory = []; // item will <main category>_<sub category> if has 2 header
  let removeColumns = ['id', 'emissions', 'view_detail', 'read_file', 'service_detail', 'basic_unit_search', 'distance_search']
  let itemErrorCount = 0;
  if(scope === 3 && category === 14) {
    removeColumns = ['id']
  }
  header.forEach(column => {
    const headerIndex = headers.findIndex(header => column.header === header.trim());
    if(category === 14 && column.binding !== 'id' && headerIndex >= 0) {
      headersBinding.push(column.binding);
    } else {
      if (headerIndex >= 0 && !removeColumns.includes(column.binding) && !column.cssClass?.includes('auto-increment')) {
        headersBinding.push(column.binding);
      }
    }
    
    if(column?.columns) {
      column.columns.forEach(subColumn => {
        if(!subColumn.cssClass?.includes('auto-increment') && !removeColumns.includes(subColumn.binding)) {
          headersBinding.push(subColumn.binding);
          headerMainSubCategory.push(`${column.header}_${subColumn.header}`)
        }
      })
    } else {
      if(category === 14 && column.binding !== 'id' && column.binding !== 'attach_file') {
        headerMainSubCategory.push(column.header)
      } else {
        if(!removeColumns.includes(column.binding) && !column.cssClass?.includes('auto-increment')) {
          headerMainSubCategory.push(column.header)
        }
      }
    }
  })

  const organizationalList_obj = listBranchObj.organizationalList_obj
  const company_name_obj = listBranchObj.company_name_obj
  const business_name_obj = listBranchObj.business_name_obj
  const country_obj = listBranchObj.country_obj
  const layer_3_obj = listBranchObj.layer_3_obj
  const layer_4_obj = listBranchObj.layer_4_obj
  const layer_5_obj = listBranchObj.layer_5_obj
  const layer_6_obj = listBranchObj.layer_6_obj
  // check header in pattern with header in csv
  let headerCsv = convertStringToArrayInHeader(array[0])?.map(item => item.replace('\r', ''))
  headerCsv = headerCsv.map(item => removeLastAsterisk(item))  
  headerCsv = updateHeaderCsvForS3c4m3(headerCsv, iss3c4m3)

  // console.log(array[1],convertToArray(array[1], cleanArray(convertToArray(array[1]))));

  console.log('header FE: ', headerMainSubCategory, 'header BE: ', headerCsv);
  if(!compareHeader(headerMainSubCategory, headerCsv)) {
    error = errorCsv;
    return { csvData: [], error };
  }
  const sourceKeyByIdeaDbSource = getIdeaDbItemName()
  const sourceKeyByItemNameDbCustomizeOrDbMasterByItemName = dbStore.getDbCustomizeOrDbMasterByItemName
  const sourceKeyByDbCustomizeOrDbMaster = dbStore.getDbCustomizeOrDbMasterByWsuSource
  const sourceKeyByWsuSource = {...sourceKeyByIdeaDbSource, ...sourceKeyByDbCustomizeOrDbMaster};
  const sourceKeyByItemName = {...sourceKeyByIdeaDbSource, ...sourceKeyByItemNameDbCustomizeOrDbMasterByItemName};
  const typePcafByLang = {}
  const gicObject = {}
  const fullTypePcaf = COMMERCIAL_DATA_TYPE1.concat(CAR_LOAN_TYPE, DATA_TYPE);
  const filterIndex = {};
  fullTypePcaf?.forEach(itemType => {
    if(!typePcafByLang?.[itemType?.value]) {
      typePcafByLang[itemType.value] = itemType.key
    }
  })
  dataGicsByYear?.forEach(item => {
    if(!gicObject?.[item?.value]) {
      gicObject[item.value] = item
    }
  })
  for (let i = 1; i < array.length; i++) {
    /* Empty object to store result in key value pair */
    const jsonObject = {}
    /* Store the current array element */
    const currentArrayString = array[i];
    let jsonProperties = convertStringToArray(currentArrayString)?.map(item => item?.replace('\r', ''))

    // stop count row error in last item
    if(jsonProperties.length === 1 && i === array.length - 1) {
      continue;
    }
    
    // stop the current iteration of a loop and move on to the next iteration when length of header not match with length of row data
    if(headersBinding.length > jsonProperties.length) {
      itemErrorCount ++
      continue;
    }
    if (typeof filterIndex[i - 1] === 'undefined') {
      filterIndex[i - 1] = [];
    }
    for (let j in headersBinding) {
      jsonObject[headersBinding[j]] = jsonProperties[j].replace('\r', '')
      // format number
      if(numberItem.includes(headersBinding[j]) || headersBinding[j] === 'emissions_factor') {
        const isNumber = $_helper_isNumberType_bySetting(jsonObject[headersBinding[j]]);
        let subStringRang = getSubstringRange(headerCsv[j])
        if(headersBinding[j] === 'emissions_factor') {
          subStringRang = 25
        }
        if (formatNumberBySetting(jsonObject[headersBinding[j]])?.includes('-')) {
          subStringRang += 1
        }
        // isNumber ?  formatValue(cellData)?.substring(0, subStringRang) : ''
        // jsonObject[headersBinding[j]] = isNumber ? formatValue(jsonObject[headersBinding[j]])?.substring(0, subStringRang) : '';
        jsonObject[headersBinding[j]] = isNumber ? formatNumberRealNum(removeSpecialChars(jsonObject[headersBinding[j]])?.substring(0, subStringRang), { isAlowMore25digit: false}) : '';
      }
      
      // this code use continue to stop check curent iteration when any condition return true
      if(headersBinding[j] === 'organizational_division' && !organizationalList_obj[jsonProperties[j]]){
        jsonObject[headersBinding[j]] = null;
        continue;
      } else if(headersBinding[j] === 'organizational_division' && organizationalList_obj[jsonProperties[j]]) {
        jsonObject[headersBinding[j]] = organizationalList_obj[jsonProperties[j]].key;  // set organizational_division to number value => 1,2,3,4
        // filterIndex[i - 1].push(headersBinding[j]);
        continue;
      }
      
      if(headersBinding[j] === 'company_name' && !company_name_obj[jsonProperties[j]]){
        jsonObject[headersBinding[j]] = null;
        continue;
      }

      else if(headersBinding[j] === 'business_name' && !business_name_obj[jsonProperties[j]]){
        jsonObject[headersBinding[j]] = null;
        continue;
      }

      else if(headersBinding[j] === 'country' && !country_obj[jsonProperties[j]]){
        jsonObject[headersBinding[j]] = null;
        continue;
      }

      else if(headersBinding[j] === 'layer_3' && !layer_3_obj[jsonProperties[j]]){
        jsonObject[headersBinding[j]] = null;
        continue;
      }

      else if(headersBinding[j] === 'layer_4' && !layer_4_obj[jsonProperties[j]]){
        jsonObject[headersBinding[j]] = null;
        continue;
      }

      else if(headersBinding[j] === 'layer_5' && !layer_5_obj[jsonProperties[j]]){
        jsonObject[headersBinding[j]] = null;
        continue;
      }

      else if(headersBinding[j] === 'layer_6' && !layer_6_obj[jsonProperties[j]]){
        jsonObject[headersBinding[j]] = null;
      }
      if (['company_name', 'business_name', 'country', 'layer_3', 'layer_4', 'layer_5', 'layer_6'].includes(headersBinding[j])) {
        filterIndex[i - 1].push(headersBinding[j]);
      }
      if(category === 15 && isSettingPcaf) {

        if(headersBinding[j] === 'classify_gics') {
          jsonObject[headersBinding[j]] = gicObject[jsonProperties[j]]?.key
        }
        
        const groupCompanyObj = dbStore.getGroupCompanyObj
        if(headersBinding[j] === 'pcaf_business_group_name' && !groupCompanyObj.group_obj[jsonProperties[j]]) {
          jsonObject[headersBinding[j]] = null;
        }
        if(headersBinding[j] === 'pcaf_business_name' && !groupCompanyObj.company_obj[jsonProperties[j]]) {
          jsonObject[headersBinding[j]] = null;
        }
      }

    }
    // get obj source
    let objSource = getObjSource(scope, category,method, methodLayer2, jsonObject, dbStore, sourceKeyByWsuSource, sourceKeyByItemName, isSettingPcaf, basicUnitData)
    //remove IDEA in basic unit => join into 1 type
    if(objSource?.content && objSource?.content.length > 0 && category === 15) {
      const newId = makeNewId(objSource?.origin_id, 2)
      objSource.id = newId

      bindingDataSource(scope, category,method, methodLayer2, objSource)
      jsonObject.idDbExternal = newId
      jsonObject.db_relation = {
        db_source_detail_id: objSource?.origin_id
      }
    }

    
    if(objSource?.type || objSource?.type === 0) { // 0,1,2 is db custom, db master, db IDEA
      if(typeof objSource.id === 'number') {
        objSource.origin_id = objSource?.id
        const newId = makeNewId(objSource?.id, objSource?.type)
        objSource.id = newId
      }
      handleAddDbMasterToList(objSource, true, true)
    }
    // set data auto fill
    bindDingValueSource({
      rowData: jsonObject,
      scope,
      category,
      dbSource: objSource,
      method,
      methodLayer2,
      isSettingPcaf,
      calcEmissions,
      qualityScore,
      gicObject,
      dataGicsByYear
    })
    if (isTkmMethod) handleFillTkmMethod(jsonObject, objSource)
    if (!(!objSource?.value_source && objSource?.value_source !== 0)) {
      jsonObject.wsu_value = formatNumberRealNum(objSource?.value_source, { isAlowMore25digit: false}) // Format value source for setting number
    }
    jsonObject.emissions = calcEmissions({...jsonObject, isLimitedAttributionFactor })  // calc emission all row
    delete jsonObject.id
    jsonObject['clientRowId'] = i;
    /* Push the genearted JSON object to resultant array */
    csvToJsonResult.push(jsonObject)
  }
  return { csvData: csvToJsonResult, error, itemErrorCount, filterIndex };
}

const updateHeaderCsvForS3c4m3 = (headerCsv, iss3s4m3) => {
  return headerCsv.map(item => item.replace(/'/g, ''))
}

const convertStringToArray = (stringText) => {
  let newArray = [];
  let replaceNumberTextArr = [];
  let replaceNumberText = stringText.replace(/"([^"]*)"/g, (match, p1, p2) => {
    const num = p1.replace(/,/g, '_');
    replaceNumberTextArr.push(num);
    return num;
  });
  newArray = replaceNumberText.split(',');
  replaceNumberTextArr.forEach(item => {
    newArray[newArray.indexOf(item)] = item.replace(/_/g, ',');
  })
  return newArray;
}

const convertStringToArrayInHeader = (stringText) => {
  let newArray = [];
  let currentStr = '';

  // iterate through each character in the string
  for (let i = 0; i < stringText.length; i++) {
    if (stringText[i] === ',' && currentStr !== '') {
      // if we encounter a comma and the current string is not empty, add it to the array
      newArray.push(currentStr);
      currentStr = '';
    } else if (stringText[i] === '"' && (i === 0 || stringText[i-1] !== '\\')) {
      // if we encounter a double quote and it is not escaped, add each character to the current string
      let j = i+1;
      while (j < stringText.length && stringText[j] !== '"') {
        currentStr += stringText[j];
        j += 1;
      }
      i = j;  // skip over the characters inside the quotes
    } else {
      // otherwise, add the current character to the current string
      currentStr += stringText[i];
    }
  }

  // add the final current string to the array
  if (currentStr !== '') {
    newArray.push(currentStr);
  }
  return newArray;
}

export const handleCalcFuelForTkmMethod = (currentItem, dbItem, binding) => {
  const isEnableLoadData = (currentItem?.wsu_unit === TKM_UNIT_SOURCE && dbItem?.type === 1)
  if(!currentItem?.wsu_unit) return

  if(currentItem?.wsu_unit !== TKM_UNIT_SOURCE) {
    currentItem.loading_data_rate = null
    currentItem.load_capacity_maximum = null
    currentItem.fuel = null
    return ''
  }

  if(currentItem.loading_data_rate && currentItem.load_capacity_maximum && isEnableLoadData) {
    currentItem.fuel = calcFuelVal(currentItem.loading_data_rate, currentItem.load_capacity_maximum, dbItem?.item_name)
  }
}

const handleFillTkmMethod = (jsonObject, objSource) => {
  const isTkmUnit = jsonObject?.wsu_unit === TKM_UNIT_SOURCE;
  const isMasterDbWithInvalidBasic = objSource?.type_db === DB_TYPE.MASTER && !isTkmBasicUnitName(objSource?.name_basic);

  // Update shared fields
  updateSharedFields(jsonObject, objSource);
  // Reset values if not a TKM unit
  if (!isTkmUnit) {
    jsonObject.load_capacity_maximum = null;
    jsonObject.loading_data_rate = null;
    jsonObject.fuel = null;
    return;
  }
  // Reset partial values for specific conditions
  if ([DB_TYPE.IDEA, DB_TYPE.CUSTOMIZE].includes(objSource?.type_db) || isMasterDbWithInvalidBasic) {
    jsonObject.load_capacity_maximum = null;
    jsonObject.loading_data_rate = null;
  } else {
    if (jsonObject?.load_capacity_maximum && !jsonObject?.loading_data_rate) {
      jsonObject.loading_data_rate = handleCalcDefaultDataRate(jsonObject?.load_capacity_maximum, objSource?.item_name) || null;
    }
    handleCalcFuelForTkmMethod(jsonObject, objSource);
  }
};

const updateSharedFields = (jsonObject, objSource) => {
  jsonObject.type_db = objSource?.type_db;
  jsonObject.type = objSource?.type_db;
  jsonObject.name_basic = objSource?.name_basic;
};
